<template>
  <div>
    <v-calendar
      ref="calendar"
      type="week"
      :weekdays="[1,2,3,4,5,6]"
      :events="courseEvents"
      :first-interval="7"
      :interval-minutes="60"
      :interval-count="14"
      :interval-height="48"
      :short-weekdays="true"
      :start="calendarStart"
      :event-color="(event) => { return event.color }"
      @click:event="showCalendarCourseDetails"
      style="max-height:700px">
      </v-calendar>
      <v-menu v-model="eventMenuActive" :activator="selectedElement" offset-y>
        <v-card color="grey lighten-4" min-width="350px" max-width="500px" flat>
          <v-toolbar :color="selectedEvent.regStatus === 'WL' ? 'red' : 'primary'" dark>
            <v-toolbar-title>{{ selectedEvent.title ? selectedEvent.title.split(' - ')[0] : '' }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <h3 v-if="selectedEvent.regStatus === 'WL'" style="margin-bottom:1em">You are currently waitlisted for this course.</h3>
            <div v-if="selectedEvent.title && selectedEvent.title.split(' - ').length > 1">
              <b class="mr-2">Title:</b>
              <span style="display:inline-block">{{ selectedEvent.title.split(' - ')[1] }}</span>
            </div>
            <div>
              <b class="mr-2" style="vertical-align:top;min-width:100px">Meets: </b>
              <span v-html="selectedEvent.meets" class="ml-4" style="display:block"></span>
            </div>
            <div v-if="selectedEvent.instructor">
              <b class="mr-2" style="vertical-align: top">Instructor: </b>
              <span style="display:inline-block">{{ selectedEvent.instructor ? selectedEvent.instructor : '' }}</span>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="eventMenuActive = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
  </div>
</template>
<script>
import { ref, computed } from '@vue/composition-api'
export default {
  props: {
    term: {
      type: String,
      required: true
    },
    termStart: {
      type: String,
      required: true
    },
    schedule: {
      type: Array,
      required: true
    }
  },
  setup (props, { root }) {
    const calendar = ref(null)
    const calendarStart = computed(() => {
      const dt = new Date(props.termStart)
      dt.setDate(dt.getDate() + 15 - dt.getDay())
      return dt.toISOString().substring(0, 10)
    })
    const eventMenuActive = ref(false)
    const selectedElement = ref(null)
    const selectedEvent = ref({})

    // const buildings = ref({
    //   KRESGE: {
    //     name: 'Anna Emma Kresge Mem. Library',
    //     map: 'https://www.google.com/maps/place/Kresge+Memorial+Library/@34.9634823,-85.3752524,18z/data=!4m5!3m4!1s0x88605b478ef77be3:0xed6a0ba9c4d174ab!8m2!3d34.9638579!4d-85.3751582'
    //   },
    //   MILLS: {
    //     name: 'C G & Nancy Mills Science Bldg',
    //     map: 'https://www.google.com/maps/place/Mills+Hall,+Lookout+Mountain,+GA+30750/@34.9647086,-85.3766385,18z/data=!3m1!4b1!4m5!3m4!1s0x88605b47804223ff:0x8519e45561689afc!8m2!3d34.9647064!4d-85.3755442'
    //   },
    //   SANDER: {
    //     name: 'Sanderson Hall',
    //     map: 'https://www.google.com/maps/place/Sanderson+Hall,+Lookout+Mountain,+GA+30750/@34.9633153,-85.3761544,18z/data=!3m1!4b1!4m5!3m4!1s0x88605b47f29b9275:0xc6b6e337e0c81230!8m2!3d34.9633131!4d-85.3750601'
    //   },
    //   BARNES: {
    //     name: 'Barnes Physical Education Ctr',
    //     map: 'https://www.google.com/maps/place/Barnes+Physical+Education+Center/@34.9616528,-85.3775971,18z/data=!4m5!3m4!1s0x88605b72721a53ed:0x31608065c8bf66d8!8m2!3d34.9616993!4d-85.3765871'
    //   },
    //   ASHE: {
    //     name: 'Martha Ashe Activity Center',
    //     map: 'https://www.google.com/maps/place/Ashe+Activity+Center,+Lookout+Mountain,+GA+30750/@34.961143,-85.3779088,18z/data=!3m1!4b1!4m5!3m4!1s0x88605b37eca6bddd:0x1754461fe6321ef3!8m2!3d34.9611408!4d-85.3768145'
    //   },
    //   FINART: {
    //     name: 'Chapel - D.M.Brown Fine Arts',
    //     map: 'https://www.google.com/maps/place/Dora+Maclellan+Brown+Memorial+Chapel,+Lookout+Mountain,+GA+30750/@34.964451,-85.3756679,18z/data=!3m1!4b1!4m5!3m4!1s0x88605b479c19d3a5:0x9842c8ed91a4516c!8m2!3d34.9644488!4d-85.3745736'
    //   },
    //   JACKSN: {
    //     name: 'Jackson Hall',
    //     map: 'https://www.google.com/maps/place/Jackson+Hall,+Lookout+Mountain,+GA+30750/@34.9640257,-85.3778891,18z/data=!3m1!4b1!4m5!3m4!1s0x88605b38684effdd:0x2dc547236f1b46b2!8m2!3d34.9640235!4d-85.3767948'
    //   },
    //   ANDREA: {
    //     name: 'Andreas Hall',
    //     map: 'https://www.google.com/maps/place/Andreas+Residence+Hall,+Lookout+Mountain,+GA+30750/@34.9624482,-85.3770823,18z/data=!3m1!4b1!4m5!3m4!1s0x88605b38040a1177:0x2eb8e0e67322ffd6!8m2!3d34.962446!4d-85.375988'
    //   },
    //   BROCK: {
    //     name: 'Brock Hall',
    //     map: 'https://www.google.com/maps/place/Brock+Hall,+Lookout+Mountain,+GA+30750/@34.9652147,-85.3753529,18z/data=!3m1!4b1!4m5!3m4!1s0x88605b477332bd71:0x48d1ff9b8722c077!8m2!3d34.9652125!4d-85.3742586'
    //   },
    //   KIRK: {
    //     name: 'The Kirk',
    //     map: 'https://www.google.com/maps/place/The+Kirk+(Covenant+College+Alumni+Center)/@34.9597568,-85.3769352,18z/data=!3m1!4b1!4m5!3m4!1s0x88605b7d950db32f:0x715ff8e33114e358!8m2!3d34.9597546!4d-85.3758409'
    //   },
    //   LUCAS: {
    //     name: 'Lucas Art Workshop',
    //     map: 'https://www.google.com/maps/place/Lucas+Art+Workshop/@34.9642745,-85.3789012,17z/data=!3m1!4b1!4m5!3m4!1s0x88605b43b8dce3cf:0x8df9f3b8e342b4bf!8m2!3d34.9642701!4d-85.3767125'
    //   },
    //   REMOTE: {
    //     name: 'Remote Learning'
    //   }
    // })

    function showCalendarCourseDetails ({ nativeEvent, event }) {
      const open = () => {
        if (event.name === 'Chapel') {
          selectedEvent.value = {
            title: 'Chapel',
            meets: 'MWF 11:00am - 11:35am in CL110',
            regStatus: 'RE'
          }
        } else {
          let eventName = event.name
          if (event.name.substr(0, 15) === '**Waitlisted** ') {
            eventName = event.name.substr(15)
          }
          selectedEvent.value = props.schedule.find(({ title }) => title === eventName)
        }
        selectedElement.value = nativeEvent.target
        setTimeout(() => { eventMenuActive.value = true }, 10)
      }

      if (eventMenuActive.value) {
        eventMenuActive.value = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    }

    const courseEvents = computed(() => {
      const arr = []
      parseMeetings({ name: 'Chapel', color: 'grey', meets: 'MWF 11:00 - 11:35' }, arr)
      // events in the calendar need name, start, and end; start and end need to be ISO formatted (YYYY-MM-DD HH:MI:SS)
      for (const { title, meets, regStatus } of props.schedule) {
        const name = (regStatus === 'WL' ? '**Waitlisted** ' : '' + title)
        const color = regStatus === 'WL' ? 'red' : 'primary'
        parseMeetings({ name, color, meets }, arr)
      }
      return arr
    })

    function parseMeetings ({ meets, name, color, category }, arr) {
      const weekDays = { M: 0, T: 1, W: 2, R: 3, F: 4, S: 5 }
      let [ year, month, day ] = calendarStart.value.split('-')
      day = parseInt(day)
      if (meets == null || meets === '' || meets === '**No Meeting Times Specified**') {
        return
      }

      // console.log(meets)

      const temp = meets.split('<br/>')
      for (const time of temp) {
        const [days, startTime, endTime] = time.replace(' - ', ' ').split(' ')
        if (startTime && endTime) {
          let [startHour, startMin] = startTime.split(':')
          let [endHour, endMin] = endTime.split(':')
          startHour = parseInt(startHour)
          if (startMin.substring(2) === 'pm' && startHour !== 12) startHour += 12
          startMin = startMin.substring(0, 2)
          const start = (startHour < 10 ? '0' : '') + startHour + ':' + startMin
          endHour = parseInt(endHour)
          if (endMin.substring(2) === 'pm' && endHour !== 12) endHour += 12
          endMin = endMin.substring(0, 2)
          const end = (endHour < 10 ? '0' : '') + endHour + ':' + endMin

          for (let k = 0; k < days.length; k++) {
            // console.log({
            //   start: year + '-' + month + '-' + (day + weekDays[days[k]]) + ' ' + start,
            //   end: year + '-' + month + '-' + (day + weekDays[days[k]]) + ' ' + end
            // })
            arr.push({
              name,
              start: year + '-' + month + '-' + (day + weekDays[days[k]]) + ' ' + start,
              end: year + '-' + month + '-' + (day + weekDays[days[k]]) + ' ' + end,
              color,
              category
            })
          }
        }
      }
    }

    return {
      calendar,
      courseEvents,
      eventMenuActive,
      calendarStart,
      selectedElement,
      selectedEvent,
      showCalendarCourseDetails
    }
  }
}
</script>
